import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Center } from 'components'
import './spinner.scss'

const 
    count = 60

class Spinner extends Component {

    static propTypes = {
        progress: PropTypes.number
    }

    circles = () =>
        {
            const
                res = []

            for ( let i = 0; i < count; i++ ) {
                res.push( <div className="circle" key={i}></div> )
            }

            return res
        }
    
    render () {
        const
            { progress } = this.props

        return (
            <Center>
                <div className="spinner">
                    {
                        this.circles()
                    }
                </div>
                {
                    !!progress && (
                        <div className="progress-bar">
                            <div className="progress-bar-loaded" style={{ width: `${progress * 100}%` }}>
                                { Math.round( progress * 100 ) }%
                            </div>
                        </div>
                    )
                }
            </Center>
        )
    }
}

export default Spinner
