const config = {
    appName: 'AinePlayer',
    paths: {
        logos: '/images/logos',
        music: '/content/music/%slug%',
        illustrations: '/images/works/%slug%/illustrations',
        portraits: '/images/works/%slug%/portraits'
    }
}

config.path = ( key, filename, slug ) => 
    (
        slug
            ? `${config.paths[key]}/${filename}`.replace( '%slug%', slug )
            : `${config.paths[key]}/${filename}`
    )

export default Object.freeze(Object.assign({}, config))