import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import { Index, View } from 'pages'

import './app.scss'

class App extends Component {
    render () {
        return (
            <div className="App">
                <Switch>
                    <Route exact path="/" component={Index} />
                    <Route exact path="/watch/:slug" component={View} />
                    <Route exact path="/watch/:slug/:index-:episode" component={View} />
                </Switch>
            </div>
        )
    }
}

export default withRouter( App )
