import React, { Component } from 'react'

import { Spinner, LogoSelector } from 'components'

import connect from './connector'

class Index extends Component {

    componentDidMount () {
        this.props.fetchWorks()
    }
    
    render () {
        const
            { works } = this.props

        if ( !works ) return (
            <section className="app-page app-page-loader">
                <Spinner />
            </section>
        )

        return (
            <section className="app-page app-page-index">
                <LogoSelector works={works} />
            </section>
        )
    }
}

export default connect( Index )
