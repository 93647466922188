import { asyncUpdate } from 'reduxigen'

import * as api from './api'

export const fetchWorks = asyncUpdate(
    'storage.works.fetch',
    () => api.getWorks(),
    ( event, state ) => ({
        ...state,
        works: event.data
    })
)

export const fetchWork = asyncUpdate(
    'storage.works.work',
    slug => api.getWork( slug ),
    ( event, state ) => ({
        ...state,
        opus: event.data
    })
)