export const prepIMG = data =>
    {
        const
            split = data.split( ':' ),
            key = split[0],
            transition = split[1],
            needs = { 
                'canvas': 'canvas.current', 
                'illustrations': 'props.preload.illustrations',
                'double': 'double.current'
            }

        return { key, transition, needs }
    }

export const prepTEXT = data =>
    {
        const
            split = data.split( ':' ),
            portrait = split[0],
            text = split[1],
            needs = {
                'popup': 'popup.current',
                'portrait': 'portrait.current',
                'name': 'name.current',
                'text': 'text.current',
                'portraits': 'props.preload.portraits',
                'persons': 'props.opus.global.portraits'
            }

        return { portrait, text, needs }
    }

export const prepHIDE = () => 
    ({
        needs: {
            'popup': 'popup.current'
        }
    })

export const prepSYNCIMG = data => prepIMG( data )

export const IMG = data => 
    {
        const
            src = data.needs.illustrations[data.key].src

        switch ( data.transition ) {
            case 'fade':
                data.needs.double.src = src
                data.needs.double.className += ' visible'
                setTimeout( () => {
                    data.needs.canvas.src = src
                    data.needs.double.className = data.needs.double.className.replace( / visible/g, '' )
                }, 500 )
                break
            default:
                data.needs.canvas.src = src
        }
    }

export const TEXT = data => 
    {
        const
            usePortrait = data.portrait !== 'null',
            person = usePortrait
                ? data.needs.persons.find( p => p.key === data.portrait )
                : null,
            pimg = usePortrait
                ? data.needs.portraits[data.portrait]
                : null

        data.needs.text.innerHTML = '<p>' + data.text.split( '|' ).join( '</p><p>' ) + '</p>'

        if ( person ) {
            data.needs.popup.className = 'text-popup'
            data.needs.name.innerText = person.name
            data.needs.portrait.src = pimg.src
        } else {
            data.needs.popup.className = 'text-popup no-portrait'
        }

        data.needs.popup.style.opacity = 1
    }

export const HIDE = data =>
    {
        data.needs.popup.style.opacity = 0
    }

export const SYNCIMG = data => IMG( data )