import React, { Component } from 'react'
import PropTypes from 'prop-types'

import * as commands from './commands'
import './player.scss'

let
    syncer = null,
    previous = null

class Player extends Component {

    static propTypes = {
        episode: PropTypes.string,
        opus: PropTypes.object,
        preload: PropTypes.object,
    }

    playButton = React.createRef()

    canvas = React.createRef()
    double = React.createRef()

    popup = React.createRef()
    portrait = React.createRef()
    name = React.createRef()
    text = React.createRef()

    componentDidMount () {
        const
            { opus, episode, preload } = this.props,
            ep = opus.series.find( s => s.slug === episode ),
            audio = preload.music[ep.sound]

        this.audio = audio
        this.episode = ep
        this.events = this.buildEvents()
        
        this.popup.current.style.opacity = 0

        this.run()
    }

    componentWillUnmount () {
        clearInterval( syncer )
    }

    buildEvents = () =>
        {
            const
                { timing } = this.episode,
                res = []
                
            Object.keys( timing ).forEach( raw => {
                const
                    time = this.getTime( raw ),
                    cmd = this.getCmd( timing[raw] )

                res.push({ time, cmd })
            })

            return res
        }

    getTime = time =>
        {
            const
                split = time.split( '.', 3 ).map( s => parseInt( s ) )

            return ( split[2] + split[1] * 1000 + split[0] * 60000 ) / 1000 
        }

    getCmd = raw => 
        {
            const
                split = raw.split( ':', 2 ),
                cmd = split[0],
                data = commands['prep' + cmd]( raw.replace( new RegExp( `^${cmd}:` ), '' ) )

            data.needs = this.needs( data.needs )

            return { cmd, data }
        }

    needs = list =>
        {
            const
                res = {},
                that = this

            if ( !list ) return res

            Object.keys( list ).forEach( key => {
                res[key] = eval( `that.${list[key]}` )
            })

            return res
        }

    run = () => syncer = setInterval( this.update, 100 )

    update = () =>
        {
            const
                current = Math.round( this.audio.currentTime * 1000 ) / 1000,
                event = this.events.filter( e => e.time <= current ).pop()

            if ( !event || event === previous ) return

            commands[event.cmd.cmd]( event.cmd.data )
            previous = event
        }

    start = () => 
        {
            this.audio && this.audio.play()
            this.playButton.current.className="play-button hidden"
        }

    render () {
        return (
            <div className="aine-player">
                <span className="play-button" ref={this.playButton} onClick={this.start}>Play</span>
                <img 
                    className = "view" 
                    ref = { this.canvas } 
                    src = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                />

                <img 
                    className = "view double" 
                    ref = { this.double }
                    src = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                />

                <div className="text-popup" ref={this.popup}>
                    <aside>
                        <img className="portrait" ref={this.portrait} />
                    </aside>
                    <main>
                        <h3 className="name" ref={this.name}></h3>
                        <div className="text" ref={this.text}></div>
                    </main>
                </div>
            </div>
        )
    }
}

export default Player
