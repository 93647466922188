import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Spinner, Center } from 'components'
import config from 'config'
import './logo-selector.scss'
import { images } from 'tools'

const
    moveTimeout = 500

let
    moveTimer = null

class LogoSelector extends Component {

    static propTypes = {
        works: PropTypes.array
    }

    state = {
        preload: null,
        current: 0,
        canSelect: true
    }

    componentDidMount () {
        if ( this.props.works && this.props.works.length ) {
            this.preload()
        }
    }

    componentWillUnmount () {
        clearTimeout( moveTimer )
    }

    componentDidUpdate ( prevProps ) {
        if ( this.props.works && this.props.works.length && ( !prevProps.works || !prevProps.works.length ) ) {
            this.preload()
        }
    }

    preload = () => 
        {
            const
                { works } = this.props,
                preload = {}

            works.forEach( work => preload[work.slug] = false )
            this.setState(
                { preload },
                () => works.forEach( 
                    work => images.preload( config.path( 'logos', work.logo ), this.done( work.slug ) ) 
                )
            )
        }

    done = slug => image =>
        {
            const
                preload = { ...this.state.preload }

            preload[slug] = image

            this.setState({ preload })
        }

    loaded = () => this.state.preload && !Object.values( this.state.preload ).includes( false )
   
    cls = index =>
        {
            const
                { current } = this.state

            if ( index === current ) return 'logo-current'
            if ( index > current ) return `logo-next-${index - current}`
            if ( index < current ) return `logo-prev-${current - index}`
        }

    set = current => () => 
        {
            if ( !this.state.canSelect ) return
            
            this.setState({ 
                current,
                canSelect: false
            })
            
            clearTimeout( moveTimer )

            moveTimer = setTimeout( () => this.setState({ canSelect: true }), moveTimeout )
        }
    
    render () {
        const
            { works } = this.props,
            { preload } = this.state

        if ( !works || !this.loaded() ) return <Spinner />

        return (
            <Center>
                <ul className="logo-selector">
                    {
                        works.map( ( work, index ) => (
                            <li key={work.slug} className={this.cls( index )} onClick={this.set(index)}>
                                <Link to={`watch/${work.slug}`}>
                                    <img src={preload[work.slug].src} title={work.title} />
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </Center>
        )
    }
}

export default LogoSelector
