/* Styles (!) Important to be at the top */
import './styles/reset.css'
import './styles/global.scss'

/* REACT */
import React from 'react'
import ReactDOM from 'react-dom'

/* REDUX */
import { Provider } from 'react-redux'
import storage from 'storage'

/* TOOLS */
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'

/* Components */
import App from './App'


ReactDOM.render(
    <Provider store={storage}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
