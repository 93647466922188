export const preload = ( filename, callback ) =>
    {
        const
            audio = document.createElement( 'audio' )

        audio.preload = true
        audio.autoplay = false
        
        audio.oncanplaythrough = e => callback( audio )
        audio.onerror = e => callback( audio )

        audio.src = filename
    }