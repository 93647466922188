import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from 'reduxigen'

export const initialState = {
    works: []
}

export default createStore(
    rootReducer( initialState ),
    applyMiddleware( thunk )
)
