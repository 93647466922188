import React, { Component } from 'react'

import { Spinner, Center, Header, Player, Series } from 'components'
import config from 'config'
import { images, music } from 'tools'

import connect from './connector'
import './view.scss'

class View extends Component {

    state = {
        illustrations: null,
        portraits: null,
        music: null,
        total: 0,
        loaded: -1
    }

    componentDidMount () {
        const
            { opus } = this.props

        ;( !opus ) 
            ? this.load()
            : this.precalc()
    }

    componentDidUpdate () {
        const
            { opus } = this.props,
            { total } = this.state

        ;( !total && opus ) && ( this.precalc() )
        ;( opus ) && ( document.title = `${opus.title} — ${config.appName}` )
    }

    precalc = () =>
        {
            const
                { opus } = this.props

            let
                total = 1

            Object.keys( opus.global ).forEach( key => {
                total += (
                    opus.global[key]
                        .filter( 
                            ( info, index ) => opus.global[key].indexOf( opus.global[key].find( n => n.file === info.file ) ) === index
                        )
                        .length
                )
            })

            this.setState({
                total,
                loaded: 1
            })

            this.preload()
        }

    preload = () =>
        {
            const
                slug = this.props.match.params.slug,
                { opus } = this.props,
                state = {
                    illustrations: {},
                    portraits: {},
                    music: {}
                }

            Object.keys( state ).forEach( key => {
                opus.global[key].map( info => {
                    const
                        handler = key === 'music' 
                            ? music.preload
                            : images.preload
                            
                    state[key][info.key] = handler( config.path( key, info.file, slug ), this.done( key, info.key ) ) 
                })
            })
            
            images.preload( config.path( 'logos', opus.logo ), () => this.setState({ loaded: this.state.loaded + 1 }) )
        }

    done = ( skey, fkey ) => img =>
        this.setState({
            [skey]: {
                ...this.state[skey],
                [fkey]: img
            },
            loaded: this.state.loaded + 1
        })

    load = () =>
        {
            const
                slug = this.props.match.params.slug

            if ( !slug ) {
                window.location = '/'
                return
            }

            this.props.fetchWork( slug )
        }

    render () {
        const
            { opus, history, match } = this.props,
            { loaded, total } = this.state,
            { params } = match,
            cls = [ "app-page app-page-view" ]

        if ( !opus || !total ) return <Spinner />
        if ( total >= loaded ) return <Spinner progress={loaded / total} />

        ;( !!params.episode ) && ( cls.push( 'watch' ) )

        return (
            <section className={cls.join( ' ' )}>
                <Center>
                    <Header logo={ opus.logo } history={history} />
                    {
                        params.episode
                            ? <Player opus={opus} episode={params.episode} preload={this.state} />
                            : <Series opus={opus} preload={this.state} />
                    }
                </Center>
            </section>
        )
    }
}

export default connect( View )
