import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './series.scss'

class Series extends Component {

    static propTypes = {
        opus: PropTypes.object,
        preload: PropTypes.object
    }

    series = () =>
        {
            const
                { opus, preload } = this.props,
                { series } = opus

            return series.map( episode => (
                <li key={episode.slug}>
                    <Link to={`/watch/${opus.slug}/${episode.index}-${episode.slug}`}>
                        {
                            episode.illustrations[0]
                                ? (
                                    <img 
                                        src = { preload.illustrations[episode.illustrations[0]].src }
                                        alt = { episode.title }
                                    />
                                )
                                : (
                                    <span className="no-illustration">{ episode.title }</span>
                                )
                        }
                        
                    </Link>
                </li>
            ))
        }

    render () {
        return (
            <div className="series-container">
                <ul className="series">
                    {
                        this.series()
                    }
                </ul>
            </div>
        )
    }
}

export default Series
