import axios from 'axios'
import { asyncUpdate as _asyncUpdate } from 'reduxigen'

const 
    { CancelToken } = axios,
    cancels = {},
    options = {
        validateStatus: () => true,
        withCredentials: true
    }

const cancel = name =>
    ({
        cancelToken: new CancelToken( function executor ( token ) { cancels[name] = token } )
    })

export const get = ( endpoint, params, uniq ) =>
    {
        const add = uniq || ''

        cancels[endpoint + add] && cancels[endpoint + add]()

        return axios.get(
            endpoint, 
            {
                params,
                options,
                ...cancel( endpoint + add )
            }
        )
    }

export const post = ( endpoint, config ) =>
    {
        const
            body = config.body ? config.body : config,
            params = config.params

        return axios.post(
            endpoint,
            body,
            {
                ...options,
                params
            }
        )
    }