import { connect } from 'react-redux'
import { apiService } from 'services'

const props = state => ({
    opus: state.opus
})

const services = {
    ...apiService
}

export default cmp => connect( props, services )( cmp )